html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Kanit;
}

.HomeButton {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 60px;
  z-index: 1000000;
}

.HomeButton:hover {
  cursor: pointer;
}

.BackButton {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 60px;
  z-index: 1000000;
}

.BackButton:hover {
  cursor: pointer;
}

.RotateButton {
  position: absolute;
  top: 15%;
  left: 15%;
  width: 150px;
  opacity: 0.5;
  /* filter: invert(100%); */
  pointer-events: none;
}

canvas {
  position: fixed;
  top: 0;
}

.infoBoxContainer {
  position: absolute;
  top: 0;
  right: 0;
  width: 80vw;
  height: 84vh;
  margin: 10vh 10vw;
  background: rgba(255, 255, 255, 0.75);
  -webkit-box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.19);
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  overflow-y: auto;
  z-index: 100000000;
}

.opaque{
  background: rgba(255, 255, 255, 0.9);
}

.infoBoxContainer video,
.infoBoxContainer img {
  width: 100%;
  padding-top: 25px;
}

.infoBoxContent {
  padding: 5%;
  padding-bottom: 0;
  // max-height:60vh;
  overflow-y: auto;
}

.infoBoxClose {
  position: absolute;
  right: 0;
  top: 0;
  padding: 20px;
  padding-top: 0;
  text-align: center;
  font-weight: bolder;
}

.infoBoxClose {
  cursor: pointer;
}

.hoverBoxContainer {
  text-align: center;
  color: #ffffff;
  width: 200px;
  margin: 8vh 8vh;
  background: #185c9c;
  -webkit-box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.19);
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  white-space: break-spaces;
}

.hoverBoxContent {
  padding: 10% 5%;
}

.hoverBoxContent h3 {
  margin-top: 0;
}

.hoverBoxContent p {
  margin-bottom: 0;
  white-space: break-spaces;
}

.pulsating-circle {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 30px;
  height: 30px;

  &:before {
    content: "";
    position: relative;
    display: block;
    width: 400%;
    height: 400%;
    box-sizing: border-box;
    margin-left: -150%;
    margin-top: -150%;
    border-radius: 50%;
    background-color: rgb(24, 92, 156);
    animation: pulse-ring 4s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 50%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    animation: pulse-dot 4s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
  }
}

.pulsating-circle-big {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 30px;
  height: 30px;

  &:before {
    content: "";
    position: relative;
    display: block;
    width: 400%;
    height: 400%;
    box-sizing: border-box;
    margin-left: -150%;
    margin-top: -150%;
    border-radius: 50%;
    background-color: rgb(24, 92, 156);
    animation: pulse-ring-big 3s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 50%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    animation: pulse-dot 3s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
  }
}

@keyframes pulse-ring {
  0% {
    transform: scale(0.33);
  }
  20%,
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

@keyframes pulse-ring-big {
  0% {
    transform: scale(0);
  }
  5% {
    opacity: 0.5;
    transform: scale(1);
  }

  100% {
    opacity: 0.5;
    transform: scale(0);
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(0.8);
  }
  12.5% {
    transform: scale(1);
  }
  25% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(0.8);
  }
}

.tooltip-sprite {
  display: none;
}

.tooltip-sprite-content {
  padding: 10px;
  background: #ffffff;
  color: rgb(24, 92, 156);
  width: 180px;
  margin-left: -90px;
  text-align: center;
  animation-name: animateSpriteIn;
  animation-duration: 0.75s;
  font-family: kanit;
  font-size: 1.5em;
}

.tooltip-sprite:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 14px;
  width: 2px;
  height: 60px;
  background-color: white;
  animation-name: animatePath;
  animation-duration: 0.75s;
}

@keyframes animatePath {
  0% {
    height: 0px;
  }
  50% {
    height: 60px;
  }
}

@keyframes animateSpriteIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.pulsating-circle:hover .tooltip-sprite {
  display: block;
}

.pulsating-circle-big:hover .tooltip-sprite {
  display: block;
}

.videoContainer {
  z-index: 1000000000;
  position: absolute;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  opacity: 1;
  background: rgba(100, 100, 100, 0.75);
  -webkit-box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.19);
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.19);
  animation-name: example;
  animation-duration: 1s;
}

.videoContainerContent {
  width: 100%;
  height: 100%;
}

.videoContainer video {
  width: 80%;
  height: 80%;
  padding: 10vh 10vw;
}

.videoContainerClose {
  position: absolute;
  right: 0;
  top: 0;
  padding: 20px;
  text-align: center;
  font-weight: bolder;
  color: #ffffff;
  z-index: 100000;
}

.videoContainerClose {
  cursor: pointer;
}

// @keyframes example {
//   0%   {width: 0vw; height: 0vh; top: 50%; right: 50%}
//   100% {width: 80vw; height: 80vh; top: 0; right: 0}
// }

@keyframes example {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.menu-sidebar {
  position: absolute;
  z-index: 10000;
  height: 100vh;
  background-color: rgb(24, 92, 156);
  color: #ffffff;
  text-transform: uppercase;
  width: 250px;
  -webkit-box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.19);
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.19);
}

.logo-container {
  padding: 20px;
  max-width: 200px;
}

.logo-container img {
  width: 100%;
}

.menu-container {
  padding: 20px;
}
.menu-container ul {
  padding-left: 0;
  list-style: none;
}
.menu-container li {
  margin-top: 20px;
}

.menu-container li li {
  margin-top: 0;
  text-transform: initial;
}

.menu-container img {
  width: 25px;
  padding-right: 15px;
  fill: #ffffff;
}

.menu-container ul ul {
  padding-left: 0;
  list-style: circle;
  padding-left: 40px;
}

.helpContainer {
  background: rgba(255, 255, 255, 0);
  -webkit-box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0);
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0);
  padding: 0;
  font-size: 1.3em;
  color: #185c9c;
  border-radius: 10px;
  transition: all 0.5s;
}

.helpContainer.open {
  background: rgba(255, 255, 255, 0.9);
  -webkit-box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.19);
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.19);
  padding: 20px 40px;
  font-size: 1.3em;
  color: #185c9c;
  border-radius: 10px;
  bottom: 50px !important;
  left: 50px !important;
}

.helpBoxClose {
  // position: absolute;
  padding: 0;
  padding-top: 0;
  height: 60px;
  text-align: center;
  font-weight: bolder;
  cursor: pointer;
  transition: all 0.5s;
}

.helpBoxClose.open {
  position: absolute;
  right: 20px;
  top: 20px;
  padding: 0;
  padding-top: 0;
  text-align: center;
  font-weight: bolder;
  cursor: pointer;
}

.contact-container {
  position: absolute;
  top: 0;
  right: 0;
  width: 25vw;
  // height: 84vh;
  margin: 25vh 20vh;
  background: rgba(255, 255, 255, 0.9);
  -webkit-box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.19);
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.19);
  padding: 100px 50px;
  font-size: 1.3em;
  color: #185c9c;
  border-radius: 10px;
}

.contact-container input[type="text"],
.contact-container input[type="email"],
.contact-container input[type="tel"] {
  border: #cccccc solid 0.5px;
  border: none;
  padding: 20px;
  width: calc(100% - 40px);
  font-size: 0.8em;
}

.contact-container input[type="radio"] {
  margin-bottom: 40px;
  margin-left: 20px;
  margin-right: 20px;
}

.contact-container button {
  color: #ffffff;
  background-color: #185c9c;
  margin-top: 40px;
  padding: 10px 20px;
  text-transform: uppercase;
  font-family: Kanit;
  font-size: 1em;
  cursor: pointer;
}

.hoverBoxContainer button {
  color: #185c9c;
  background-color: #ffffff;
  padding: 10px 20px;
  text-transform: uppercase;
  font-family: Kanit;
  font-size: 1em;
  cursor: pointer;
  border: none;
}

.CloseButton {
  width: 60px !important;
}

.HelpButton {
  width: 60px !important;
}
